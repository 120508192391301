import {
  AppDocumentCreatorUploadStatus,
  AppDocUploadContext,
  PRO_AppDocResume,
} from '@mabadive/app-common-model';
import React, { useCallback, useState } from 'react';
import { useAppInputFileUpload } from '../../../../_common-browser';
import {
  AppButton,
  AppDialogModal,
  AppDialogModalFixedBar,
  AppDocFileTypeIcon,
  AppDropzone,
  AppDropzoneLoadedFile,
  AppLoaderSpinner,
} from '../../../../business/_core/modules/layout';
import { AppIconsAction } from '../../../../business/_core/modules/layout/icons';
import { AppInputDatePickerMaterial } from '../../../../stories/components/04-form';
import { AppFormControlV2 } from '../AppFormControl';
import { AppFormLabel } from '../AppFormLabel';

export const AppDocResumeFileUploader = ({
  initialViewMode = 'dropzone',
  uploadUrl,
  uploadContext,
  onSuccess,
  onCancel,
}: {
  initialViewMode?: 'dropzone' | 'uploading' | 'preview';
  uploadUrl: string;
  uploadContext: AppDocUploadContext;
  onSuccess: (appDoc: PRO_AppDocResume) => any;
  onCancel: () => any;
}) => {
  const [selectedFile, setSelectedFile] =
    React.useState<AppDropzoneLoadedFile>();
  const [uploadStatus, setUploadStatus] =
    React.useState<AppDocumentCreatorUploadStatus>('pending');

  const [viewMode, setViewMode] =
    useState<'dropzone' | 'uploading' | 'preview'>(initialViewMode);

  const [isOpen, setIsOpen] = useState(true);

  const [docDate, setDocDate] = useState<Date>();
  const [docExpirationDate, setDocExpirationDate] = useState<Date>();

  const { isFileLoading, uploadFile: uploadFileCore } =
    useAppInputFileUpload<PRO_AppDocResume>({
      onSuccess: (appDoc, event) => {
        setUploadStatus('success');
        setViewMode('preview');
        onSuccess(appDoc);
        setIsOpen(false);
      },
      onError: (err) => {
        setUploadStatus('error');
      },
    });

  const uploadFile = useCallback(async () => {
    if (!selectedFile) return;

    setViewMode('uploading');
    setUploadStatus('uploading');

    const context: AppDocUploadContext = {
      ...uploadContext,
      fileType: selectedFile.fileType,
      docDate,
      docExpirationDate,
    };
    await uploadFileCore(selectedFile.file, {
      url: uploadUrl,
      json: {
        payload: { context },
      },
    });
  }, [
    docDate,
    docExpirationDate,
    selectedFile,
    uploadContext,
    uploadFileCore,
    uploadUrl,
  ]);

  const onCancelClick = useCallback(() => {
    setIsOpen(false);
    onCancel();
  }, [onCancel]);

  return (
    <AppDialogModal
      open={isOpen}
      className="bg-white"
      maxHeight="md:max-h-[900px]"
      maxWidth="md:max-w-2xl"
      expandWidth={true}
      expandHeight={true}
      setOpen={(isOpen) => {
        if (!isOpen) {
          onCancelClick();
        } else {
          setIsOpen(true);
        }
      }}
    >
      <div className="w-full app-p-content grid gap-y-4 gap-x-8">
        {viewMode === 'dropzone' && (
          <AppDropzone
            maxFileSizeLabel="10 Mo"
            showAcceptedExtensions={true}
            acceptFileTypes={['richText', 'image', 'text']}
            label="Ajouter un fichier"
            onFileLoaded={(file) => {
              setSelectedFile(file);
              setViewMode('preview');
            }}
          />
        )}
        {viewMode === 'uploading' && (
          <>
            {isFileLoading && (
              <div className="flex gap-2 items-center">
                <AppLoaderSpinner className="flex-shrink-0 w-6 h-6 md:w-8 md:h-8 bg-app-primary" />{' '}
                envoi du fichier en cours...
              </div>
            )}
            {uploadStatus === 'error' && (
              <div className="flex gap-2 items-center">
                <div className="text-sm text-status-error font-bold uppercase">
                  Erreur lors de l'enregistrement
                </div>
                <AppButton
                  color="primary-outline-light"
                  onClick={() => {
                    // retry
                    uploadFile();
                  }}
                >
                  Ré-essayer
                </AppButton>
              </div>
            )}
          </>
        )}
        {viewMode === 'preview' && selectedFile && (
          <div className="my-4 grid gap-8">
            <AppFormLabel label="Fichier sélectionné" labelFontSize="md">
              <div className="flex gap-8 justify-between items-center flex-wrap">
                <div className="my-4 flex gap-2">
                  <AppDocFileTypeIcon
                    fileType={selectedFile.fileType}
                    className="text-app-primary w-6 h-6"
                  />
                  <div className="text-gray-600 font-medium">
                    {selectedFile.file?.name}
                  </div>
                </div>
                <AppButton
                  icon={AppIconsAction.delete}
                  color={'danger-outline-light'}
                  onClick={() => {
                    setSelectedFile(undefined);
                    setViewMode('dropzone');
                  }}
                >
                  Supprimer
                </AppButton>
              </div>
            </AppFormLabel>
            {uploadContext.docRef === 'insurance-proof' ? (
              <AppFormControlV2
                className="w-full max-w-72"
                label="Date d'expiration"
                required={true}
                renderComponent={() => (
                  <AppInputDatePickerMaterial
                    initialView={'weeks'}
                    value={docExpirationDate}
                    onChange={(newDocExpirationDate) => {
                      setDocExpirationDate(newDocExpirationDate);
                    }}
                  />
                )}
              />
            ) : (
              <AppFormControlV2
                className="w-full max-w-72"
                label="Date du document"
                required={true}
                renderComponent={() => (
                  <AppInputDatePickerMaterial
                    initialView={'weeks'}
                    className="w-full"
                    dialogZIndex="z-[2000]"
                    value={docDate}
                    onChange={(newDocDate) => {
                      setDocDate(newDocDate);
                    }}
                  />
                )}
              />
            )}
          </div>
        )}
      </div>
      <AppDialogModalFixedBar>
        <div className="flex gap-8 justify-between md:justify-center">
          <AppButton
            className="w-full uppercase sm:max-w-xs"
            fullWidth={true}
            size="normal"
            icon={AppIconsAction.back}
            color={'gray-outline-light'}
            onClick={onCancelClick}
          >
            Annuler
          </AppButton>
          {viewMode === 'preview' && (
            <AppButton
              className="w-full uppercase sm:max-w-xs"
              color={'primary-outline-light'}
              size="normal"
              // disabled={viewMode === 'uploading'}
              icon={AppIconsAction.upload}
              onClick={uploadFile}
            >
              Valider
            </AppButton>
          )}
        </div>
      </AppDialogModalFixedBar>
    </AppDialogModal>
  );
};

import {
  BookingCustomerPayment,
  ClubPurchasePaymentPackage,
  ClubResumePublicDiveCenter,
  Customer_ClubSettingsPublicCustomer,
} from '@mabadive/app-common-model';
import { dataSorter, dateService } from '@mabadive/app-common-services';
import React, { useCallback, useMemo } from 'react';
import {
  AppIcons,
  AppIconsAction,
  useAppDialogModal,
} from 'src/business/_core/modules/layout';
import { AppMultilineText } from 'src/business/club/modules/_common/form';
import { AppPriceLabel } from 'src/business/club/modules/_common/ui';
import { useAppCurrency } from 'src/pages';
import { BookingPaymentCardOpenPaymentLinkDialogContent } from './BookingPaymentCardOpenPaymentLinkDialogContent';
import { BookingPaymentCardProduct } from './BookingPaymentCardProduct';

export const BookingPaymentCard = ({
  diveCenter,
  clubSettings,
  purchasePayment,
  className,
}: {
  diveCenter: ClubResumePublicDiveCenter;
  clubSettings: Customer_ClubSettingsPublicCustomer;
  purchasePayment: BookingCustomerPayment;
  className?: string;
}) => {
  const diveCenterPublicData = diveCenter?.publicData;
  const clubPublicData = clubSettings?.publicData;
  const clubPublicSettings = clubSettings?.publicSettings;
  const appDialogModal = useAppDialogModal();

  const logoSquareUrl = clubSettings.publicData.brand.logoSquareUrl;

  const paymentPackages = useMemo(() => {
    return dataSorter.sortMultiple(purchasePayment.purchasePaymentPackages, {
      getSortAttributes: (x: ClubPurchasePaymentPackage) => [
        {
          value: x.purchasePackage.purchaseDate,
          type: 'default',
        },
        {
          value: x.purchaseProductPackage?.reference,
          type: 'full-text',
        },
        {
          value: x.purchasePackage._id,
          type: 'full-text',
        },
      ],
      asc: true,
    });
  }, [purchasePayment.purchasePaymentPackages]);

  const showPaymentLinkDialog = useCallback(
    ({ clubPurchasePaymentId }: { clubPurchasePaymentId: string }) => {
      appDialogModal.openDialog(({ close }) => ({
        content: (
          <div className="app-p-content">
            <BookingPaymentCardOpenPaymentLinkDialogContent
              clubPurchasePaymentId={clubPurchasePaymentId}
              onConfirm={() => close()}
              onCancel={() => close()}
            />
          </div>
        ),
      }));
    },
    [appDialogModal],
  );

  const paymentCurrency = useAppCurrency(purchasePayment.usedCurrencyIsoCode);

  return (
    <div className={className}>
      {/* <h2 className="text-lg font-medium text-gray-900">Order summary</h2> */}

      {/* <AppDateTimeLabel
            timezone="utc"
            date={purchase.purchaseDate}
            format="date"
          /> */}
      {/* <BillingParticipantPaymentResumeTableDiverName
              diver={purchase.contact}
              className={'flex-shrink'}
            /> */}

      {/* <ClubPurchasesExtraCostDescription
            className="text-xs"
            extraCosts={purchase?.extraCosts}
          /> */}

      <div className="h-full mt-4 rounded-lg border border-gray-200 bg-white shadow-sm grid px-4 sm:px-6 ">
        <div className="flex gap-4 justify-between">
          <h2 className="py-2 flex gap-2">
            <div className="flex-shrink-0">
              {logoSquareUrl ? (
                <img
                  src={logoSquareUrl}
                  className={'inline max-h-[35px] max-w-[35px]'}
                  alt="logo"
                />
              ) : (
                <AppIcons.ui.emptyPhoto
                  className={'inline max-h-[35px] max-w-[35px]'}
                  title="Votre logo ici"
                />
              )}
              {/* <img
          src={
            'https://tailwindui.com/img/ecommerce-images/checkout-page-02-product-01.jpg'
          }
          //   alt={product.imageAlt}
          className="w-20 rounded-md"
        /> */}
            </div>{' '}
            <div>
              <div className="text-base font-bold leading-4 uppercase ">
                {diveCenterPublicData?.brand?.name}
              </div>
              <div className="text-sm leading-normal uppercase text-gray-600">
                {diveCenterPublicData?.brand?.locationResume}
              </div>
            </div>
          </h2>
          <div className="text-xs font-medium text-gray-400 p-1.5">
            {dateService.formatUTC(purchasePayment.creationDate, 'DD/MM/YYYY')}
          </div>
        </div>
        <div className="self-stretch">
          <h3 className="sr-only">Produits commandés</h3>
          <ul role="list" className="divide-y divide-gray-200">
            {paymentPackages.map((purchasePaymentPackage, i) => (
              <BookingPaymentCardProduct
                key={i}
                purchasePaymentPackage={purchasePaymentPackage}
                clubSettings={clubSettings}
                paymentCurrency={paymentCurrency}
              />
            ))}
          </ul>
        </div>
        <dl className="self-end space-y-6 py-3">
          {purchasePayment.customerComment && (
            <div className="flex gap-2 border-t border-gray-200 text-base text-gray-600">
              <AppIcons.ui.comment className="mt-1 h-6 w-6" />
              <AppMultilineText
                className=""
                lineClassName="my-0.5"
                text={purchasePayment.customerComment}
              />
            </div>
          )}
          <div className="border-t border-gray-200 flex items-center justify-between">
            {/* <dt className="text-base font-medium">Total</dt> */}
            <dt className="text-base font-medium">
              <div>Montant à payer</div>
              {purchasePayment.dueDate && (
                <div className="text-sm font-normal text-gray-500">
                  avant le{' '}
                  {dateService.formatUTC(purchasePayment.dueDate, 'DD/MM/YYYY')}
                </div>
              )}
            </dt>
            <dd className="text-base font-medium text-gray-900">
              <AppPriceLabel
                className="text-green-600"
                amount={purchasePayment.usedCurrencyAmount}
                mainCurrency={paymentCurrency}
              />
            </dd>
          </div>
        </dl>
        <div className="self-end border-t border-gray-200 py-6">
          <button
            type="submit"
            className="w-full rounded-md border border-transparent bg-app-primary py-3 px-4 text-base font-medium text-white shadow-sm hover:bg-app-primary-dark focus:outline-none focus:ring-2 focus:ring-app-primary focus:ring-offset-2 focus:ring-offset-gray-50"
            onClick={() =>
              showPaymentLinkDialog({
                clubPurchasePaymentId: purchasePayment._id,
              })
            }
          >
            <div className="flex gap-4 justify-center">
              <AppIconsAction.creditCard className="w-6 h-6" />
              Payer en ligne
            </div>
          </button>
        </div>
      </div>
    </div>
  );
};
